import React from "react"


const AboutPage = () => {
  return (
    <main>
      <title>About Page</title>
      <h1>
        About BingePlan
      </h1>
      <p>Here is a description.</p>
    </main>
  )
}

export default AboutPage
